import React from 'react'
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'
import { HomePage } from './pages/home/HomePage'

export const UnAuthenticatedAppRoutes = () => {
  const unAuthenticatedRouter = createBrowserRouter([
    {
      path: '/',
      element: <HomePage />
    },
    { path: '*', element: <Navigate to="/" /> }
  ])

  return <RouterProvider router={unAuthenticatedRouter} />
}
