import React from 'react'
import { authStore, initAuthStore } from '../../../lib/authentication'
import {
  getAvailableKcEnvironments,
  getKcEnvironmentForHostRegion,
  getRedirectUrisForHost
} from '../../../lib/kc-environments'

const kcEnvironments = getAvailableKcEnvironments(window.location.host)

export const RegionSelector = () => {
  const loginToRegion = (region: AuthRegion) => {
    const host = window.location.host
    const kcHost = getKcEnvironmentForHostRegion(host, region)
    const redirectUri = getRedirectUrisForHost(host)[region]

    return initAuthStore(kcHost, redirectUri).catch(() => authStore.login())
  }

  return (
    <li className="relative flex items-center justify-end !col-[11_/_span_2]">
      <button className="hover:cursor-pointer btn outlined">Login</button>

      <ul className="card-list w-[calc(100vw-2rem)] md:w-[25rem]">
        <li>
          <h2 className="!bg-white text-lg">Select region</h2>
        </li>
        {kcEnvironments.map(region => (
          <li key={region} className="!shadow-none">
            <button className="px-6 py-6 text-left uppercase" onClick={() => loginToRegion(region as AuthRegion)}>
              {region}
            </button>
          </li>
        ))}
      </ul>
    </li>
  )
}
