import React from 'react'
import heroImage from '../../../images/200w-300.jpg'
import { Wave } from '../../shared/Wave'

export const Header = () => {
  return (
    <>
      <div
        className="relative w-full flex justify-center items-center px-6 h-[70vh] bg-cover bg-no-repeat bg-center lg:h-[50rem] lg:bg-[bottom_80%_left]"
        style={{ backgroundImage: `url(${heroImage})` }}
      >
        <div className="w-full grid grid-cols-12 gap-6 max-w-screen-xl px-6">
          <div className="col-span-12 md:col-span-4">
            <h1 className="text-3xl leading-[1.25] lg:text-[3rem] lg:leading-[1.32]">
              Start digitalizing the customer experience
            </h1>
          </div>
        </div>
      </div>

      <Wave />
    </>
  )
}
