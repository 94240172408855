import { visit } from 'unist-util-visit'
import { h } from 'hastscript'

export default function remarkAdmonition(): any {
  const alertTypes = ['info', 'caution', 'warning', 'danger']

  return (tree: any) => {
    visit(tree, node => {
      if (node.type === 'textDirective' || node.type === 'leafDirective' || node.type === 'containerDirective') {
        if (!alertTypes.includes(node.name)) {
          return
        }

        const data = node.data || (node.data = {})
        const tagName = 'admonition'

        data.hName = tagName
        data.hProperties = h(tagName, { ...node.attributes, type: node.name }).properties
      }
    })
  }
}
