import React, { FunctionComponent, useEffect, useState } from 'react'
import QRCode from 'qrcode'
import { logError } from '@tomra/datadog-browser-logging'

export const QRCodeRenderer: FunctionComponent<{ node: any }> = ({ node }) => {
  const [dataURL, setDataURL] = useState<string | null>(null)
  const [error, setError] = useState<string>('')

  const { children: value } = node

  useEffect(() => {
    QRCode.toDataURL(value, { width: 800 })
      .then(url => {
        setDataURL(url)
      })
      .catch(error => {
        logError(new Error('Unable to render QR code', error))
        setError('Unable to render QR code')
      })
  }, [node])

  if (error) {
    return <div className="my-6 p-6 card text-center font-bold text-warm-red">Unable to render QRCode</div>
  }

  return dataURL ? (
    <div className="my-6 pb-6 card">
      <img alt={value} src={dataURL} className="w-full" />
      <div className="text-sm text-center -mt-xl">{value}</div>
    </div>
  ) : null
}
