import React, { FunctionComponent } from 'react'
import { Link, useLocation } from 'react-router-dom'

export type MenuItem = {
  id: string
  label: string
  operationName?: string
  path: string
  children: MenuItem[]
}

type Props = {
  sitePrefix?: string
  menuTitle?: string
  menuItems: MenuItem[]
}

export const VerticalMenu: FunctionComponent<Props> = ({ menuTitle, sitePrefix, menuItems }) => {
  const location = useLocation()

  return (
    <div className="sticky top-6 flex flex-col h-[calc(var(--vh)*100-4rem)] space-y-6 overflow-y-scroll">
      <h2 className="breadcrumbs">
        {sitePrefix && <span>{sitePrefix}</span>}
        <span>{menuTitle}</span>
      </h2>

      {menuItems.map(item => {
        const hasChildren = item.children.length > 0

        const id = `menu-${item.label}`

        const parentIsActive = item.path === location.pathname + location.hash || item.path === location.hash

        return (
          <ul key={id} className="side-menu">
            <li>
              <h2 className="subpixel-antialiased">
                <Link to={item.path} className={parentIsActive ? 'active' : ''}>
                  {item.label}
                </Link>
              </h2>

              {hasChildren && (
                <ul>
                  {item.children.map((child: any) => {
                    const childIsActive =
                      child.path === location.pathname + location.hash || child.path === location.hash

                    const hasOperationName = !!child.operationName

                    return (
                      <li key={`li-${child.id}`}>
                        <Link to={`${child.path}`} className={`!grid !grid-cols-4 ${childIsActive ? 'active' : ''}`}>
                          {hasOperationName ? (
                            <>
                              <span className={`col-span-1 operation-name ${child.operationName}`}>
                                {child.operationName}
                              </span>
                              <span className="col-span-3">{child.label}</span>
                            </>
                          ) : (
                            <span className="col-span-4">{child.label}</span>
                          )}
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              )}
            </li>
          </ul>
        )
      })}
    </div>
  )
}
