type DocLink = {
  level: number
  heading: string
  children: DocLink[]
  id: string
}

export const createMenuFromMarkdown = (markdown: string, path: string) => {
  if (!markdown) {
    return {
      title: '',
      menuItems: []
    }
  }

  const headings = markdown.split('\n').filter(isHeading)
  const links = headings.map(createDocLinkForHeading)
  const menus = links.filter(isNotH1).reduce(sortByLevel, {})

  return {
    title: links.find(isH1)?.heading ?? '',
    menuItems: Object.keys(menus).map(menu => ({
      id: menus[menu].id,
      label: menus[menu].heading,
      path: `${path}#${menu}`,
      children: menus[menu].children.map((child: { id: string; heading: string }) => ({
        id: child.id,
        label: child.heading,
        path: `${path}#${child.id}`,
        children: []
      }))
    }))
  }
}

const isH1 = (l: DocLink) => l.level === 1
const isNotH1 = (l: DocLink) => l.level > 1

const isHeading = (l: string) => l.startsWith('#')

const removeHashes = (string: string) => string.replaceAll('#', '')

const getHeaderLevel = (heading: string) => {
  const hashes = heading.match(/#+/g) as string[]
  return hashes[0].length ?? 0
}

const createIdFromHeading = (heading: string) => {
  return heading
    .replaceAll(/[#:().]/gm, '')
    .trim()
    .replaceAll(' ', '-')
    .toLowerCase()
}

const createDocLinkForHeading = (heading: string) => {
  const level = getHeaderLevel(heading)
  const sectionId = createIdFromHeading(heading)

  return { level, heading: removeHashes(heading).trim(), children: [], id: sectionId }
}

const sortByLevel = (acc: any, link: DocLink) => {
  const parentIds = Object.keys(acc).filter(key => acc[key].level < link.level)
  const parentId = parentIds[parentIds.length - 1]

  if (parentId) {
    const parent = acc[parentId]
    acc = {
      ...acc,
      [parentId]: { ...parent, children: [...parent.children, { ...link, id: link.id }] }
    }
  } else {
    acc = { ...acc, [link.id]: link }
  }

  return acc
}
