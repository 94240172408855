import { AuthStore, WebStorage } from '@tomra/client-side-authentication'
import * as localforage from 'localforage'
import { KEYCLOAK_HOST } from './environment'
import { jwtDecode } from 'jwt-decode'

export const storage: WebStorage = {
  setItem: localforage.setItem as (key: string, value: string) => Promise<void>,
  getItem: localforage.getItem as (key: string) => Promise<string>,
  removeItem: localforage.removeItem,
  clear: localforage.clear
}

export let authStore: AuthStore

export const initAuthStore = (keycloakHost?: string, redirectUri?: string) => {
  authStore = new AuthStore(
    'TomraConnectUsers',
    'developer-portal-ui',
    keycloakHost || KEYCLOAK_HOST,
    'en',
    storage,
    redirectUri
  )
  return authStore.init()
}

const getDecodedToken = () => {
  if (authStore) {
    try {
      const token = authStore.getToken()
      return jwtDecode(token) as any
    } catch {
      return null
    }
  }
}

export const hasDeveloperPortalLicense = () => {
  const decodedToken = getDecodedToken()
  return !!decodedToken?.scoped_resource_access?.some((resource: string) => resource.includes('DEVELOPER_PORTAL_READ'))
}
