declare global {
  type AuthRegion = 'eu' | 'au' | 'us'
}

const prodEnvironments = {
  eu: 'https://id.eu.prod.tomra.cloud/auth',
  au: 'https://id.au.prod.tomra.cloud/auth',
  us: 'https://id.us.prod.tomra.cloud/auth'
}

const testEnvironments = {
  eu: 'https://id.eu.test.tomra.cloud/auth',
  au: 'https://id.au.test.tomra.cloud/auth',
  us: 'https://id.us.test.tomra.cloud/auth'
}

const isLocalhost = (host: string) => host.match(/(:\d{4})/gi)
const isTestStage = (host: string) => host.match(/(test.)/gi)

export const getKcEnvironmentForHost = (host: string) => {
  const region = (host.match(/(au.|eu.|us.)/g)?.[0]?.slice(0, -1) as AuthRegion) || ('eu' as AuthRegion)
  return isLocalhost(host) || isTestStage(host) ? testEnvironments[region] : prodEnvironments[region]
}

export const getKcEnvironmentForHostRegion = (host: string, region: AuthRegion) => {
  return isLocalhost(host) || isTestStage(host) ? testEnvironments[region] : prodEnvironments[region]
}

export const getAvailableKcEnvironments = (host: string) => {
  return isLocalhost(host) || isTestStage(host)
    ? (Object.keys(testEnvironments) as AuthRegion[])
    : (Object.keys(prodEnvironments) as AuthRegion[])
}

export const getRedirectUrisForHost = (host: string) => {
  return isLocalhost(host)
    ? {
        eu: 'http://localhost:3000',
        au: 'http://localhost:3000',
        us: 'http://localhost:3000'
      }
    : isTestStage(host)
      ? {
          eu: 'https://test.developer.tomra.cloud',
          au: 'https://au.test.developer.tomra.cloud',
          us: 'https://us.test.developer.tomra.cloud'
        }
      : {
          eu: 'https://developer.tomra.cloud',
          au: 'https://au.prod.developer.tomra.cloud',
          us: 'https://us.prod.developer.tomra.cloud'
        }
}
