import React from 'react'

export const Wave = () => {
  return (
    <div className="relative w-full h-20 mx-auto">
      <div className="absolute left-0 bottom-0 w-full h-[130%]">
        <div className="animate-[waves_8s_ease-in-out_infinite] bg-bg-color h-[100px]"></div>
      </div>
    </div>
  )
}
