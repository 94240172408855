import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'

export const LinkRenderer: FunctionComponent<{ node: { href: string; children: string } }> = ({ node }) => {
  const { href, children: text } = node

  const isDevPortalLink = href.startsWith('/') || href.startsWith('#')

  return isDevPortalLink ? (
    <Link to={href}>{text}</Link>
  ) : (
    <a href={href} target="_blank" rel="noreferrer">
      {text}
    </a>
  )
}
