type EnvironmentConfig = {
  ENV: string
  STAGE: string
  VERSION: string
  CONFIG_BY_STAGE: { [key: string]: { CONFIG_BY_ENV: { [key: string]: any }; AMPLITUDE_CONFIG: { apiKey: string } } }
  DATADOG_CONFIG: { rumApplicationId: string; clientToken: string; service: string; site: string }
}

// @ts-ignore
const envConfig = window.__ENVIRONMENT__ as EnvironmentConfig

const { ENV: ENVIRONMENT, DATADOG_CONFIG, VERSION, STAGE, CONFIG_BY_STAGE } = envConfig
const { AMPLITUDE_CONFIG } = CONFIG_BY_STAGE[STAGE]
const { KEYCLOAK_HOST, API_HOST } = CONFIG_BY_STAGE[STAGE].CONFIG_BY_ENV[ENVIRONMENT]

const isLocalhost = !!window.location.href.match(/localhost/g) || !!window.location.href.match(/127.0.0.1/g)

export { ENVIRONMENT, VERSION, KEYCLOAK_HOST, API_HOST, STAGE, AMPLITUDE_CONFIG, isLocalhost, DATADOG_CONFIG }
