import React from 'react'
import { RegionSelector } from '../pages/home/RegionSelector'
import tomraArrow from '@tomra/consumer-customer-design-system/config/svg/tomra.svg'

export const HomeNavBar = () => {
  return (
    <nav className="menu">
      <ul className="px-6 2xl:px-0">
        <li className="!col-[1_/_span_7]">
          <a href="/" className="flex items-center space-x-4 text-lg">
            <img className="h-[1.5rem]" src={tomraArrow} alt="TOMRA logo" />
            <span className="text-xl">TOMRA Collection Developer</span>
          </a>
        </li>
        <RegionSelector />
      </ul>
    </nav>
  )
}
